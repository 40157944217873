import React from 'react';
import Pink  from '../../Comp/img/pinkswap.png';
import './Pink.css';
import { NavLink } from 'react-router-dom';

const PinkSale = () => {
  return (
    <>
      <div className='container my-5'>
        <div className='row'>
           <div className='col-lg-10 m-auto'>
            <div className='row my-5'> 

            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-5'>

               <h1 className='  ' style={{fontWeight:'600'}}><span className=' text-rainbow-animation'> Available on PinkSale </span></h1>
      
                <p className='mt-4 text-white' style={{textAlign:'center', textAlign:'justify', lineHeight:'30px'}}>Don't miss the chance of a lifetime! Prepare yourself for an epic journey as we gear up to launch our presale on PinkSale. Stay tuned and be among the first to seize this incredible opportunity. The clock is ticking – secure your spot and dive into the future!</p>
                 <div>
                 <NavLink to='https://www.pinksale.finance/launchpad/0xBbB94cC7fe7d5625beDF5FDb6CB527eeCE6e30CA?chain=BSC'> <button  class="TRYNOW  text-rainbow-animation">Join Presale</button>
                  </NavLink>
                 </div>
            </div>
                   

            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-5 pt-2'>

               
                  <div style={{display:'flex', justifyContent:'center'}}>

                  <img src={Pink} alt='#' className='pinksale' style={{width:'50%'}}/>

                  </div>
  
                 </div>
               </div>
           </div>
        </div>
      </div>
    </>
  )
}

export default PinkSale;