import React,{useState} from 'react'
import {BsFillSendFill} from 'react-icons/bs'
import {BsDiscord} from 'react-icons/bs'
import {AiOutlineTwitter} from 'react-icons/ai'
import {FiYoutube} from 'react-icons/fi'
import {FaLinkedinIn} from 'react-icons/fa'
import {FaTelegramPlane} from 'react-icons/fa';
import {MdCopyright} from 'react-icons/md'
import {AiOutlineReddit} from 'react-icons/ai'
import {AiOutlineMedium} from 'react-icons/ai'
import Logo from '../../Comp/img/logo (5).png'
import { NavLink } from 'react-router-dom'

const Footer = () => {

   const [Subscribe, setSubscribe] = useState("");
   const [Click, setClick] = useState("");

   const handledCheck = ()=>{

    if(Subscribe.length>=1){
      setClick("click");
    }
    else{
      setClick("notClick");
    }
   
    
   }

  return (
    <>
    <section style={{backgroundColor:'rgb(0 23 46)'}}>
      <div className='container '>
       <div className='row py-5'> 

        <div className='col-lg-2 col-md-6 col-sm-12'>

        <img src={Logo} alt='img' style={{width:'70%', marginTop:'-9px'}}  /> 

        <h5 className='text-white mt-4' style={{lineHeight:'30px'}}>ARMOR AI-<br/>
          Revolutionizing<br/> <span className='Subscribe1'> Blockchain </span>technology<br/> with advanced <span className='Subscribe1'> Al</span> With Safety.
        </h5>
        </div>

        <div className='col-lg-2 col-md-6 col-sm-12'>
         
        <h5 className='text-white text-left Utility ps-3' style={{cursor:'pointer'}}> Ouick Links</h5>

        <ul className='unorder ps-3' style={{listStyle:'none',color:'#fff', lineHeight:'40px', textAlign:'left',paddingLeft:'0%'}}>


       <NavLink to='/Roadmap' style={{textDecoration:'none', color:'#fff'}}>
        <li>Roadmap</li>
        </NavLink> 
        <NavLink to='/Career' style={{textDecoration:'none',color:'#fff'}}><li>Career</li></NavLink> 
        <NavLink to='/WhitePaper' style={{textDecoration:'none',color:'#fff'}}><li>WhitePaper</li></NavLink> 
        <NavLink to='/TokenSystem' style={{textDecoration:'none',color:'#fff'}}> <li>TokenSystem</li> </NavLink> 
        <NavLink to='/SportLight' style={{textDecoration:'none',color:'#fff'}}><li>  Spot Light on Ai  </li></NavLink> 
        {/* <NavLink to='/Sdk' style={{textDecoration:'none',color:'#fff'}}><li>Product</li></NavLink>  */}
        </ul>

        </div>

        <div className='col-lg-2 col-md-6 col-sm-12'>
         
        <h5 className='text-white  Utility ps-0' style={{cursor:'pointer'}}>Utility page</h5> 
        <ul className='unorder ps-0' style={{listStyle:'none',color:'#fff', textAlign:'left', lineHeight:'40px',paddingLeft:'0%'}}> 
       <NavLink to='/Guide' style={{textDecoration:'none', color:'#fff'}}><li>Brand Guide</li></NavLink> 
       <NavLink to='/Agreement' style={{textDecoration:'none', color:'#fff'}}> <li>Agreement for Sale Of Tokens</li></NavLink>
       <NavLink to='/PrivacyPolicy' style={{textDecoration:'none', color:'#fff'}}> <li>Privacy Policy</li> </NavLink>
        </ul> 

        </div> 

        <div className='col-lg-2 col-md-6 col-sm-12'>
         <h5 className='text-white Utility ps-3' style={{cursor:'pointer'}}>Products</h5> 
         <h5 className=' text-white Utility  mt-3 ps-3' style={{cursor:'pointer'}}>Developer Tools</h5> 
       
         <ul className='unorder ps-3' style={{listStyle:'none',color:'#fff', textAlign:'left', lineHeight:'40px',paddingLeft:'0%'}}>
         
         <NavLink to="/Sdk" style={{textDecoration:'none', color:'#fff'}}><li> S D K</li></NavLink>
         <NavLink to='/Sdk' style={{textDecoration:'none', color:'#fff'}}><li> API</li></NavLink>
        
         <NavLink to='' style={{textDecoration:'none', color:'#fff'}}> <h5 > <span className='Utility'> AI Tools </span> </h5> </NavLink>
         <NavLink to='/Chat' style={{textDecoration:'none', color:'#fff'}}> <li>  Safe Ai  </li>
          </NavLink>
          <NavLink to='/Generate' style={{textDecoration:'none', color:'#fff'}}><li> Armor IG</li></NavLink>

          </ul> 
        </div>  

        <div className='col-lg-4 col-md-6 col-sm-12'>
         
        <h4 className='text-white' style={{cursor:'pointer'}}>Subscribe to be in touch</h4>

           <h5 className='text-white my-4' style={{cursor:'pointer'}}>* Only valuable resources, no bullshit.</h5>

         <div  class="mb-3 d-flex "> 
            <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Enter Your Email " style={{backgroundColor:'transparent',padding:'8px', width:'60%', borderRadius:'10px', color:'#fff', boxShadow:'none', border:Click=="notClick"?"1px solid red":"",}}
            value={Subscribe}
            onChange={(e)=>{
              setSubscribe(e.target.value);
            }}/> 
            

            <div>
            <button className='ms-2 Subscribe' style={{backgroundColor:'transparent', color:'#fff',border:'1px solid #fff', padding:'10px 12px', borderRadius:'8px'}}  
            onClick={handledCheck} >{Click=="click"?"Subscribed":"Subscribe"}</button>
            </div>
            </div>

            <div className='row mt-4'>

            <div className='col-lg-10 col-sm-12'>
             <div className='row'> 

             <div className='col-lg-2 col-sm-6 col-3 mt-3'>
             <NavLink to='https://twitter.com/Armor_ai01' style={{textDecoration:'none'}}>
              <div>
               <AiOutlineTwitter className='twit' style={{color:'#fff', border:'1px solid #fff', padding:'10px', fontSize:'48px', borderRadius:'10px'}}/>
                 </div>
                 </NavLink>  
             </div>

             <div className='col-lg-2 col-sm-6 col-3 mt-3'>
           

             <NavLink to='https://www.linkedin.com/company/armor-ai/'>  
              <div>  <FaLinkedinIn className='twit' style={{color:'#fff', border:'1px solid #fff', padding:'10px', fontSize:'48px', borderRadius:'10px'}}/>  </div>
              </NavLink>
              </div>

             <div className='col-lg-2 col-sm-6 col-3 mt-3'>
              
             <NavLink to='https://discord.com/channels/1095283216782471168/1095283218804117589'>
              <div><BsDiscord className='twit' style={{color:'#fff', border:'1px solid #fff', padding:'10px', fontSize:'48px', borderRadius:'10px'}}/>  </div>
             </NavLink>

             </div>

             <div className='col-lg-2 col-sm-6 col-3 mt-3'>
             <NavLink to='https://www.youtube.com/channel/UCUDtCqK3DRnrVj_02RjP8vA'>
              <div>  <FiYoutube className='twit' style={{color:'#fff', border:'1px solid #fff', padding:'10px', fontSize:'48px', borderRadius:'10px'}}/>  </div>
              </NavLink>
              </div>


            </div> 


            <div className='row '> 
            
            
             

             <div className='col-lg-2 col-sm-6 col-3 mt-3'>
             <NavLink to='https://medium.com/@aiarmor42'>
              <div>  <AiOutlineMedium className='twit' style={{color:'#fff', border:'1px solid #fff', padding:'10px', fontSize:'48px', borderRadius:'10px'}}/>  </div>
            </NavLink>
              </div>

             <div className='col-lg-2 col-sm-6 col-3 mt-3'>
             <NavLink to='https://www.reddit.com/user/ArmorAi'>
              <div>  <AiOutlineReddit className='twit' style={{color:'#fff', border:'1px solid #fff', padding:'10px', fontSize:'48px', borderRadius:'10px'}}/>  </div>
            </NavLink>
              </div>

             <div className='col-lg-2 col-sm-6 col-3 mt-3'>
             <NavLink to='https://t.me/armorai01'>
              <div>  <FaTelegramPlane className='twit' style={{color:'#fff', border:'1px solid #fff', padding:'10px', fontSize:'48px', borderRadius:'10px'}}/>  </div>
            </NavLink>
              </div>

              

              

            </div>
            </div>
            </div>

        </div>
       </div>
         
     

     
      </div>



      <div className='container-fluid  ' >
      <div className='row' style={{backgroundColor:'#030614'}}>
       <div className='col-lg-12 col-sm-12'>
       
       <p className='text-white text-center pt-3'  > 
       <span className=' Subscribe1'  > info@armorai.io</span>  </p>
       
       
       </div>
      </div>
     </div>


      <div className='container-fluid pt-1'>
      <div className='row' style={{backgroundColor:'#030614'}}>
       <div className='col-lg-12 col-sm-12'>
       
       <p className='text-white text-center pt-3' style={{fontSize:'18px', cursor:'pointer'}}> <span className='Subscribe1' style={{fontSize:'18px'}}> Armor Ai</span> <span><MdCopyright/></span> 2023, Dubai </p>
       
       
       </div>
      </div>
     </div>


 
      </section>
     
    </>
  )
}

export default Footer