import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { RiUserVoiceLine } from "react-icons/ri";
import Logo from "../../Comp/img/Gr.png";
import "./ChatAI.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { BsTwitter } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { NavLink } from "react-router-dom";

// const YOUR_API_KEY = "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";

const SlowText = ({ text, delay,handleHideCursor }) => {
  // console.log(text);
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    handleHideCursor();
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex === text.length - 1) {
        clearInterval(intervalId);
        return;
      }
      if (currentIndex == 0) {
        setDisplayText((prevText) => prevText + text[currentIndex]);
      }
      setDisplayText((prevText) => prevText + text[currentIndex]);
      currentIndex++;
    }, delay);

    return () => {
      clearInterval(intervalId);
    };
  }, [text, delay]);

  return <p>{displayText}</p>;
};

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState([]);
  const [input, setInput] = useState("");
  const [isWriting, setIsWriting] = useState(false);
  const [reqMessages, setReqMessages] = useState([]);
  const cursorRef = useRef(null);

  const chatContainerRef = useRef(null);
  //function for handling scrolling chat to bottom
  const scrollToBottom = () => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };

  const sendMessage = async (x) => {
    // console.log('tofind',x);
    // handleShowCursor();
    if (x.trim() === "") return;

    // setMessages([...messages, { text: input, user: true }]);
    setNewMessage([{ text: x, user: true }]);
    setInput("");

    try {
      const response = await axios.post(
        "https://api.armorai.io/api/chat",
        {
          search: x ,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${YOUR_API_KEY}`,
          },
        }
      );

      const botResponse = response.data.choices[0].message.content;

      console.log(botResponse, "after response by bot");
      setNewMessage((prevMessage) => [
        ...prevMessage,
        { text: botResponse, user: false },
      ]);
      
      // setMessages((prevMessages) => [
      //   ...prevMessages,
      //   { text: botResponse, user: false },
      // ]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const historyMapFun = (xyz) => {
    if (xyz.length === 0) {
      setReqMessages([]);
      return;
    }
    const reqXyz = xyz.filter((el, ind) => ind < xyz.length - 2);
    setReqMessages([...reqXyz]);
    return reqXyz;
  };


  const handleShowCursor =()=>{
    cursorRef.current.style.display = 'flex'
  }

  const handleHideCursor =()=>{
    cursorRef.current.style.display = 'none'
  }

  useEffect(() => {
    cursorRef.current.style.display = 'none'
    // console.log(cursorRef.current)
    historyMapFun(messages);
    scrollToBottom();
  }, []);

  return (
    <>

    <div>
      <Header/>
    </div>
      <div className="container ">
        <div className="row">
          <div className="col-lg-10 m-auto pb-5">
            <div className="row"></div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <div class="stage">
              <div class="floor threed"></div>
              <div class="leftwall threed"></div>
              <div class="rightwall threed"></div>
              <div class="center threed"></div>
              <div class="ceiling threed"></div>
            </div>
          </div>

          <div className="container  ">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-12   ">
                <div className="mobile">
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>

                    <div
                      style={{
                        transform:
                          "translateY(-145px) rotateX(90deg) scale(0.3)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(-137.5px) rotateX(90deg) scale(0.41)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(-125px) rotateX(90deg) scale(0.56)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(-110px) rotateX(90deg) scale(0.685)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(-92.5px) rotateX(90deg) scale(0.79)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(-75px) rotateX(90deg) scale(0.87)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(-62.5px) rotateX(90deg) scale(0.91)",
                      }}
                    >
                      {" "}
                    </div>
                    <div
                      style={{
                        transform:
                          "translateY(-50px) rotateX(90deg) scale(0.945)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(-37.5px) rotateX(90deg) scale(0.965)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(-25px) rotateX(90deg) scale(0.975)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(-12.5px) rotateX(90deg) scale(0.985)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform: "translateY(0px) rotateX(90deg) scale(1)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(12.5px) rotateX(90deg) scale(0.985)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(25px) rotateX(90deg) scale(0.975)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(37.5px) rotateX(90deg) scale(0.965)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(50px) rotateX(90deg) scale(0.945)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(62.5px) rotateX(90deg) scale(0.91)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(75px) rotateX(90deg) scale(0.87)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(92.5px) rotateX(90deg) scale(0.79)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(110px) rotateX(90deg) scale(0.685)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(125px) rotateX(90deg) scale(0.56)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(137.5px) rotateX(90deg) scale(0.41)",
                      }}
                    ></div>
                    <div
                      style={{
                        transform:
                          "translateY(145px) rotateX(90deg) scale(0.3)",
                      }}
                    ></div>
                    <div></div>
                    <div></div>
                  </div>

                  <div
                    className="mobileLogo"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src={Logo}
                      alt=""
                      className="ArmorLogo "
                      style={{ width: "50%", marginTop: "100px" }}
                    />
                  </div>
                </div>

                <div>
                  <div
                    className="icon11 "
                    style={{ position: "absolute", top: "84%", left: "4%" }}
                  >
                    <NavLink to="/" style={{ textDecoration: "none" }}>
                      {" "}
                      <button className="Twi me-4">
                        {" "}
                        <span className="IG"> Home</span>
                      </button>
                    </NavLink>

                    <NavLink to="/Generate" style={{ textDecoration: "none" }}>
                      {" "}
                      <button className="Twi">
                        {" "}
                        <span className="IG">Armor IG </span>
                      </button>
                    </NavLink>

                    <br />
                    <br />
                  </div>
                </div>
              </div>
              {/* 
              <div className="col-lg-1 col-md-4 col-sm-12  ">

              </div> */}

              <div className="col-lg-7 col-md-8 col-sm-12 chartmobile mt-5">
                <h1 className="text-center text-white mt-2 chartmobile2">
                  {" "}
                  Safe AI <span className="ChatBox"> ChatBox </span>
                </h1>

                <div className="p-3 guy" style={{}}>
                  <div className="my-3">
                    <div className="row  p-2" style={{}}>
                      <div className="col-lg-12 col-sm-12 col-12">
                        <div
                          className="p-3 "
                          style={{
                            height: "60vh",
                            borderRadius: "10px",
                            border: "2px #fff dashed",
                            overflowY: "scroll",
                            overflow: "auto",
                          }}
                        >
                          <div className="row">
                            <div className="col-lg-12 col-sm-12">
                              <div
                                className="chatbot-container text-white"
                                ref={chatContainerRef}
                              >
                                <div>
                                  <div
                                  // style={{
                                  //   display: "flex",
                                  //   // flexDirection: message.user
                                  //   //   ? "row-reverse"
                                  //   //   : "row",
                                  // }}
                                  >
                                    <div
                                      className="mt-1"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {" "}
                                      <img
                                        src={Logo}
                                        alt="#"
                                        className="imghh me-3 mb-3"
                                        style={{ width: "32px" }}
                                      />{" "}
                                      <p
                                        style={{
                                          backgroundColor: "#fff",
                                          padding: "5px",
                                          color: "#000",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        {" "}
                                        Welcome to Safe Ai !{" "}
                                      </p>{" "}
                                    </div>

                                    {messages?.map((message, index) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: message.user
                                            ? "row-reverse"
                                            : "row",
                                        }}
                                      >
                                        <div>
                                          {message.user ? (
                                            <RiUserVoiceLine
                                              className="ms-3"
                                              style={{
                                                color: "#fff",
                                                fontSize: "20px",
                                                margin: "20px 2px 2px 2px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={Logo}
                                              alt="#"
                                              className="imghh me-3"
                                              style={{
                                                width: "22px",
                                                margin: "10px 2px",
                                              }}
                                            />
                                          )}
                                        </div>

                                        {/* safe Ai is Typing part is pending */}

                                        <div
                                          key={index}
                                          className={`chatbot-message ${
                                            message.user ? "user" : "ai"
                                          }`}
                                          style={{
                                            margin: "15px 2px",
                                            backgroundColor: "#fff",
                                            color: "#000",
                                            padding: "2px 5px",
                                            borderRadius: "8px",
                                          }}
                                        >
                                          {message?.text}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                               
                                {/*   anwer is here*/}
                                {newMessage?.map((message, index) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: message.user
                                        ? "row-reverse"
                                        : "row",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {message.user ? (
                                        <RiUserVoiceLine className="ms-3  RiUser" />
                                      ) : (
                                        <img
                                          src={Logo}
                                          alt="#"
                                          className="imghh me-3"
                                          style={{ width: "22px" }}
                                        />
                                      )}
                                    </div>

                                    {/* safe Ai is Typing part is pending */}
                                   

                                    {message?.text.length > 0 ? (
                                      <div
                                        key={index}
                                        className={`chatbot-message   ${
                                          message.user ? "user" : "ai"
                                        }`}
                                        style={{
                                          margin: "0%",
                                          margin: "15px 2px",
                                          backgroundColor: "#fff",
                                          color: "#000",
                                          borderRadius: "7px",
                                          padding: "0px 7px 0px 7px",
                                        }}
                                      >
                                        {message.user ? (
                                          <p
                                            style={{
                                              backgroundColor: "#fff",
                                              color: "#000",
                                              borderRadius: "7px",
                                              margin: "0%",
                                            }}
                                          >
                                            {message?.text}
                                          </p>
                                        ) : (
                                         <>
                                         
                                          
                                          <SlowText
                                          handleHideCursor={handleHideCursor}
                                          text={message?.text}
                                          delay={100}
                                        />
                                         
                                        
                                         </>
                                        )}
                                      </div>
                                    ) : (
                                     <></>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>


                          <div ref={cursorRef}>
                            <div style={{display:'flex', alignItems:'center'}}>
                               <img src={Logo} alt="#" className="me-3" style={{ width: "21px"}}  /> 
                                <p className="pt-3" style={{color:'gray'}}>Safe AI is Thinking......</p></div>
                        </div>
                        </div>
                      </div>
                  
                    </div>
                    
                    <div className="row  ">
                      <div className="col-lg-9 col-sm-12 mt-4">
                        <input
                          type="text"
                          className="textaar"
                          placeholder="Ask a question..."
                          value={input}
                          onChange={(e) => setInput(e.target.value)}
                          onKeyDown={(e) => {
                            const { target, key } = e;
                            const { value } = target;
                            if (key === "Enter") {
                              handleShowCursor();
                              scrollToBottom();
                              setMessages((prev) => [...prev, ...newMessage]);
                              sendMessage(value);
                              setIsWriting(false);
                            } else {
                              // setInput(value);
                              setIsWriting(true);
                            }
                          }}
                        ></input>
                      </div>

                      <div className="col-lg-3 col-sm-12 mt-4">
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <button
                            className="ASk"
                            onClick={() => {
                              // console.log({
                              //   messages,
                              //   newMessage,
                              // });
                              handleShowCursor();
                              scrollToBottom();
                              setMessages((prev) => [...prev, ...newMessage]);
                              setIsWriting(false);
                              sendMessage(input);
                            }}
                          >
                            ASK Safe AI
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="icoMobile mb-5 ">
                  {" "}
                  <NavLink to="/" style={{ textDecoration: "none" }}>
                    {" "}
                    <button className="Twi me-4">
                      {" "}
                      <span className="IG"> Home</span>
                    </button>
                  </NavLink>
                  <NavLink to="/Generate" style={{ textDecoration: "none" }}>
                    {" "}
                    <button className="Twi">
                      {" "}
                      <span className="IG">Armor IG </span>
                    </button>
                  </NavLink>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="mt-4">
        <Footer/>
      </div>
    </>
  );
};

export default Chat;
