import React ,{useState,useEffect}from 'react' 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Comp/Header/Header';
import Roadmap from './Comp/Roadmap/Roadmap';
import Home from './Comp/Home/Home';
import Career from './Comp/Career/Career';
import WhitePaper from './Comp/WhitePaper/WhitePaper';
import TokenSystem from './Comp/TokenSystem/TokenSystem';
import Sdk from './Comp/Sdk/Sdk';
import { SportLight } from './Comp/SportLight/SportLight';
import Chat from './Comp/ChatBox/Chat';
import Generate from './ImageGenerate/Generate';
import PrivacyPolicy from './Policy/PrivacyPolicy';
import Guide from './Policy/Guide';
import Agreement from './Policy/Agreement ';
import Staking from './Comp/Stacking/Staking';


const App = () => {

  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return function cleanup() {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, []);

  const checkScrollTop = () => {
    if (window.pageYOffset > 300) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (

    <div>
     

    <BrowserRouter>
     
    <Routes> 
      <Route path="/" Component={Home}/> 
      <Route path="Roadmap" Component={Roadmap}/> 
      <Route path="Career" Component={Career}/> 
      <Route path="WhitePaper" Component={WhitePaper}/>  
      <Route path="TokenSystem" Component={TokenSystem }/>  
      <Route path="Sdk" Component={Sdk}/>  
      <Route path="SportLight" Component={SportLight}/>  
      <Route path="Chat" Component={Chat}/>  
      <Route path='Generate' Component={Generate}/>
      <Route path='PrivacyPolicy' Component={PrivacyPolicy}/>
      <Route path='Guide' Component={Guide}/>
      <Route path='Agreement' Component={Agreement}/>
      <Route path='Staking' Component={Staking}/> 
    </Routes>
  </BrowserRouter>
    
    </div>

  )
}

export default App;