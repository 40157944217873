import React from 'react'
import Header from '../Header/Header'
import rrr from '../../Comp/img/Li.png'
import Footer from '../Footer/Footer'

const Roadmap = () => {
  return (
   
     <>

     <div>
     <Header/>
     </div>
       <section> 
       <div className='container' style={{paddingTop:'120px'}}>
        <div className='row'>
         <div className='col-lg-11 col-md-8 col-sm-12 m-auto'>  
          <div className='row'>    
          <div className='col-lg-12 col-sm-12'>
          
            <h1 className='Roadmap' style={{fontSize:"50px", textAlign:'center', color:'#fff'}}>Our <span className='Subscribe1'> Roadmap </span> to Protect<br/> Humanity from AI</h1>
            
                  <div className='mt-4' style={{display:'flex', justifyContent:'center'}}>
                  
                  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
                   style={{fontWeight:'500', fontSize:'22px'}}> Phase - 1 </span></button>
                  
                  </div>


          </div>





    
          </div> 


           
         </div>
        </div>
       </div>


          {/*Desktop view start*/}
         <div className='container Desk mt-5'>
          <div className='row   '> 
              
          <div className='col-lg-6 col-md-6 col-sm-12 ' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
           <div className='row ' > 
           
             <div className='col-lg-3 col-md-6 col-sm-12'>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 p-0' >
            <div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
            <div className='card-body text-white'>  
              <h5 className='QQ'>2022 Q4</h5>
              <p>Research and Development on AI's Algorithms.</p> 
            </div>   
           </div> 
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
             <div>
               <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
             </div>
            </div>

           </div>
          </div>
              
          <div className='col-lg-6 col-md-6 col-sm-12' style={{display:'flex', justifyContent:'left'}}>
           <div className='row p-0' style={{marginTop:'100px'}}>  
           
           <div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
           <div>
             <img src={rrr} alt='image' style={{width:'100%'}}/>
           </div>
          </div>


            <div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
            <div className='card mahakal' style={{backgroundColor:'#030614', }}>
            <div className='card-body text-white'>  
              <h5 className='QQ'>2022 Q4</h5>
              <p>Finding Bugs that will mislead humans and harm humans in future. </p> 
            </div>   
           </div> 
            </div>

           </div>
          </div>
            
         </div> 
 


     


         <div className='row  '> 
              
          <div className='col-lg-6 col-md-6 col-sm-12 ' style={{ borderRight:'3px solid #0E152F' , }}>
           <div className='row ' > 
           
             <div className='col-lg-3 col-md-6 col-sm-12'>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 p-0' >
            <div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
            <div className='card-body text-white'>  
              <h5 className='QQ'>2022 Q4</h5>
              <p>Alpha Stage Research on AI to optimize and make it more secure, 
              Recruitment of best Data Scientists, Machine Learning developers,
               Blockchain Developers. </p> 
            </div>   
           </div> 
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
             <div>
               <img src={rrr} alt='image' className='mt-5 pt-5' style={{width:'100%',  }}/>
             </div>
            </div>

           </div>
          </div>
               
            
         </div> 


          

             <div className='row  mt-5  '> 

                <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
                  
                  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
                  style={{fontWeight:'500', fontSize:'22px'}}>Phase - 2 </span></button>
                  
                  </div>
             <div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
              <div className='row ' > 
              
                <div className='col-lg-3 col-md-6 col-sm-12'>
               </div>
   
               <div className='col-lg-6 col-md-6 col-sm-12 p-0' >
               <div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
               <div className='card-body text-white'>  
                 <h5 className='QQ'>2023 Q-1</h5>
                 <p>Understanding AI's Algorithms to Optimized. </p> 
               </div>   
              </div> 
               </div>
               <div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
                <div>
                  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
                </div>
               </div>
   
              </div>
             </div>
                 
             <div className='col-lg-6 col-md-6 col-sm-12' style={{display:'flex', justifyContent:'left'}}>
              <div className='row  p-0' style={{marginTop:'100px'}}>  
              
              <div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
              <div>
                <img src={rrr} alt='image' style={{width:'100%'}}/>
              </div>
             </div>
   
   
               <div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
               <div className='card mahakal ' style={{backgroundColor:'#030614', }}>
               <div className='card-body text-white'>  
                 <h5 className='QQ'>2023 Q-1</h5>
                 <p>Collecting all leading API's of Ai Systems. Making a system that will adapt to any Ai Systems and make it more secure ecosystem for users. </p> 
               </div>   
              </div> 
               </div>
   
              </div> 
             </div>
               
            </div> 



            <div className='row     '> 

         
         <div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
          <div className='row ' > 
          
            <div className='col-lg-3 col-md-6 col-sm-12'>
           </div>

           <div className='col-lg-6 col-md-6 col-sm-12 p-0' >
           <div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
           <div className='card-body text-white'>  
             <h5 className='QQ'>2023 Q-1</h5>
             <p>Working to make Stable System that will deploy on any AI Algorithm. </p> 
           </div>   
          </div> 
           </div>
           <div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
            <div>
              <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
            </div>
           </div>

          </div>
         </div>
             
         <div className='col-lg-6 col-md-6 col-sm-12' style={{display:'flex', justifyContent:'left'}}>
          <div className='row  p-0' style={{marginTop:'100px'}}>  
          
          <div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
          <div>
            <img src={rrr} alt='image' style={{width:'100%'}}/>
          </div>
         </div>


           <div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
           <div className='card mahakal ' style={{backgroundColor:'#030614', }}>
           <div className='card-body text-white'>  
             <h5 className='QQ'>2023 Q-1</h5>
             <p>Raise Funding and keep moving towards Developers SDK kit.</p> 
           </div>   
          </div> 
           </div>

          </div> 
         </div>
           
        </div>




        <div className='row     '> 

         
        <div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
        <div className='row ' > 
         
           <div className='col-lg-3 col-md-6 col-sm-12'>
          </div>

          <div className='col-lg-6 col-md-6 col-sm-12 p-0' >
          <div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
          <div className='card-body text-white'>  
            <h5 className='QQ'>2023 Q-1</h5>
            <p>Working to make Stable System that will deploy on any AI Algorithm. </p> 
          </div>   
         </div> 
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
           <div>
             <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
           </div>
          </div>

         </div>
        </div>
             
          
       </div>

        


      

        


       <div className='row  mt-5  '> 

       <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
         
         <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
         style={{fontWeight:'500', fontSize:'22px'}}>Phase - 3</span></button>
         
         </div>
    <div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
     <div className='row ' > 
     
       <div className='col-lg-3 col-md-6 col-sm-12'>
      </div>

      <div className='col-lg-6 col-md-6 col-sm-12 p-0' >
      <div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
      <div className='card-body text-white'>  
        <h5 className='QQ'>2023 Q-2</h5>
        <p>Launching our token on BNB Smart chain.</p> 
      </div>   
     </div> 
      </div>
      <div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
       <div>
         <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
       </div>
      </div>

     </div>
    </div>
        
    <div className='col-lg-6 col-md-6 col-sm-12'  >
     <div className='row  p-0' style={{marginTop:'100px'}}>  
     
     <div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
     <div>
       <img src={rrr} alt='image' style={{width:'100%'}}/>
     </div>
    </div>


      <div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
      <div className='card mahakal ' style={{backgroundColor:'#030614', }}>
      <div className='card-body text-white'>  
        <h5 className='QQ'>2023 Q-2</h5>
        <p>Get Ready for Presales on pinksale Platform, Investorns get Half of the price per token.  </p> 
      </div>   
     </div> 
      </div>

     </div> 
    </div>
      
   </div> 

   <div className='row'> 
 
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
 <div className='row ' > 
 
   <div className='col-lg-3 col-md-6 col-sm-12'>
  </div>

  <div className='col-lg-6 col-md-6 col-sm-12 p-0' >
  <div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
  <div className='card-body text-white'>  
    <h5 className='QQ'>2023 Q-2</h5>
    <p>Finish ICO and Launch.</p> 
  </div>   
 </div> 
  </div>
  <div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
   <div>
     <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
   </div>
  </div>

 </div>
</div>
    
<div className='col-lg-6 col-md-6 col-sm-12'  >
 <div className='row  p-0' style={{marginTop:'100px'}}>  
 
 <div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
 <div>
   <img src={rrr} alt='image' style={{width:'100%'}}/>
 </div>
</div>


  <div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
  <div className='card mahakal ' style={{backgroundColor:'#030614', }}>
  <div className='card-body text-white'>  
    <h5 className='QQ'>2023 Q-2</h5>
    <p>Develop Liquidity and Payment visibility on Cross-chain.</p> 
  </div>   
 </div> 
  </div>

 </div> 
</div>
  
</div> 









<div className='row'> 
 
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
 <div className='row ' > 
 
   <div className='col-lg-3 col-md-6 col-sm-12'>
  </div>

  <div className='col-lg-6 col-md-6 col-sm-12 p-0' >
  <div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
  <div className='card-body text-white'>  
    <h5 className='QQ'>2023 Q-2</h5>
    <p>Listing on Leading Decentralized Exchanges. </p> 
  </div>   
 </div> 
  </div>
  <div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
   <div>
     <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
   </div>
  </div>

 </div>
</div>
    
<div className='col-lg-6 col-md-6 col-sm-12'  >
 <div className='row  p-0' style={{marginTop:'100px'}}>  
 
 <div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
 <div>
   <img src={rrr} alt='image' style={{width:'100%'}}/>
 </div>
</div>


  <div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
  <div className='card mahakal ' style={{backgroundColor:'#030614', }}>
  <div className='card-body text-white'>  
    <h5 className='QQ'>2023 Q-2</h5>
    <p>Begin Alpha stage SDK kit Script Creation.</p> 
  </div>   
 </div> 
  </div>

 </div> 
</div>
  
</div> 



       



{ /*PHASE 4 start */}

  

<div className='row  mt-5  '> 

<div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
  style={{fontWeight:'500', fontSize:'22px'}}> Phase - 4 </span></button>
  
  </div>
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-3</h5>
 <p>Deploying and Testing to Developers Network. </p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12' style={{display:'flex', justifyContent:'left'}}>
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-3</h5>
 <p>Recruitment of Developers Who Test and Run a Testing Node Campaign.</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 

  

<div className='row '> 

 
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-3</h5>
 <p>Burn 2.5% of Token from Total suply. </p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-3</h5>
 <p>Working on Stable Ecosystem for Developers.</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 
 

{ /*PHASE 4 end */}


{ /*PHASE 5 start */}

 

<div className='row  mt-5  '> 

<div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
  style={{fontWeight:'500', fontSize:'22px'}}>Phase - 5 </span></button>
  
  </div>
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-4</h5>
 <p>Begin Marketing Campaign for Developers to build on Armor AI's Network  </p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-4</h5>
 <p>Deploying and Testing to the test network with test nodes.</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 

 

<div className='row   '> 
 
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-4</h5>
 <p>AMA Campaign.  </p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2023 Q-4</h5>
 <p>Completed Armor Ai Developers SDK kit.</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 


{ /*PHASE 5 End */}






{ /*PHASE 6 start */}


<div className='row  mt-5  '> 

<div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
  <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
  style={{fontWeight:'500', fontSize:'22px'}}> Phase - 6 </span></button>
  
  </div>
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-1</h5>
 <p>Launch Armor Ai on Mainnet. </p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-1</h5>
 <p>Deploying Huge Marketing Campaign Investors.</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 


<div className='row '> 
 
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-1</h5>
 <p>Airdrops Campaign for exesting Investors. </p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
<div className='col-lg-6 col-md-6 col-sm-12'  >
<div className='row  p-0' style={{marginTop:'100px'}}>  

<div className='col-lg-3 col-md-6 col-sm-12 p-0 mt-5 ' style={{marginRight:'-50'}}>
<div>
<img src={rrr} alt='image' style={{width:'100%'}}/>
</div>
</div>


<div className='col-lg-6 col-md-6 col-sm-12  p-0 '>
<div className='card mahakal ' style={{backgroundColor:'#030614', }}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-1</h5>
 <p>Launch Fee-Paying Model on Our API's and SDK's.</p> 
</div>   
</div> 
</div>

</div> 
</div>

</div> 







<div className='row '> 
 
<div className='col-lg-6 col-md-6 col-sm-12 pt-5' style={{ borderRight:'3px solid #0E152F' ,height:'40vh',margin:'0%'}}>
<div className='row ' > 

<div className='col-lg-3 col-md-6 col-sm-12'>
</div>

<div className='col-lg-6 col-md-6 col-sm-12 p-0' >
<div className='card mahakal p-0' style={{backgroundColor:'#030614',}}>
<div className='card-body text-white'>  
 <h5 className='QQ'>2024 Q-1</h5>
 <p>Leading CEX onbord and Listing. (Binance, Bybit , KUcoin, Gate.io, CoinBase, etc). </p> 
</div>   
</div> 
</div>
<div className='col-lg-3 col-md-6 col-sm-12 p-0'  >
<div>
  <img src={rrr} alt='image' className='mt-5 pt-3' style={{width:'100%',  }}/>
</div>
</div>

</div>
</div>
 
 

</div> 


{ /*PHASE 6 End */}


       
           </div>  
           {/*Desktop view end*/}


           {/*mobile view start*/}
                
           <div className='container Mob'>
            <div className='row p-3 '>

            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-4</h5>
               <p>Research and Development on AI's Algorithms.</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-4</h5>
               <p>Finding Bugs that will mislead humans and harm humans in future. </p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-4</h5>
               <p>Alpha Stage Research on AI to optimize and make it more secure, Recruitment of best Data Scientists, Machine Learning developers, Blockchain Developers. </p> 
             </div>   
            </div>  
             </div>
          
             
            </div>




            {/*  Phase 2 start */}

            <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}><span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}> Phase - 2 </span></button>
            
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-1</h5>
               <p>Understanding AI's Algorithms to Optimized. </p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-1</h5>
               <p>Collecting all leading API's of Ai Systems. Making a system that will adapt to any Ai Systems and make it more secure ecosystem for users.</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-1</h5>
               <p>Working to make Stable System that will deploy on any AI Algorithm. </p> 
             </div>   
            </div>  
             </div>


            <div className='col-lg-3 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2022 Q-1</h5>
               <p>Raise Funding and keep moving towards Developers SDK kit. </p> 
             </div>   
            </div>  
             </div>
          
             
            </div>






            <div className='row p-3'>
            
            <div className='col-lg-3 col-md-6 col-sm-12'>
               
            <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
            <div className='card-body text-white'>  
              <h5 className='QQ'>2022 Q-1</h5>
              <p>AMA Campaign </p> 
            </div>   
           </div>  
            </div>
            </div>
            {/*  Phase 2 end */}







            {/*  Phase 3 start */}

            <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}>Phase - 3 </span></button>
            
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Launching our token on BNB Smart chain. </p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Get Ready for Presales on pinksale Platform, Investorns get Half of the price per token. </p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Finish ICO and Launch. </p> 
             </div>   
            </div>  
             </div>


       
          
             
            </div>

            <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}> Phase - 3 </span></button>
            
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Develop Liquidity and Payment visibility on Cross-chain. </p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Listing on Leading Decentralized Exchanges.  </p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Begin Alpha stage SDK kit Script Creation. </p> 
             </div>   
            </div>  
             </div>


       
          
             
            </div>




            {/*  Phase 3 end */}



















            {/*  Phase 4 start */}

            <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}> Phase - 4 </span></button>
            
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-3</h5>
               <p>Deploying and Testing to Developers Network. </p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Recruitment of Developers Who Test and Run a Testing Node Campaign.</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Burn 2.5% of Token from Total suply. </p> 
             </div>   
            </div>  
             </div>


       
          
             
            </div>

            <div className='row p-3 '>
            
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1  ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-2</h5>
               <p>Working on Stable Ecosystem for Developers.</p> 
             </div>   
            </div>  
             </div>
          
          
             


       
          
             
            </div>




            {/*  Phase 4 end */}










            {/*  Phase 5 start */}

            <div className='row p-3 '>
            <div className='my-5' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}>Phase - 4 </span></button>
            
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-4</h5>
               <p>AMA Campaign. </p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-4</h5>
               <p>Deploying and Testing to the test network with test nodes.</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-4</h5>
               <p>Begin Marketing Campaign for Developers to build on Armor AI's Network  </p> 
             </div>   
            </div>  
             </div>


       
          
             
            </div>


            
            <div className='row p-3 '>
           
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2023 Q-4</h5>
               <p>Completed Armor Ai Developers SDK kit. </p> 
             </div>   
            </div>  
             </div>
           


       
          
             
            </div> 

            {/*  Phase 5 end */} 



            {/*  Phase 6 start */}

            <div className='row p-3 '>
            <div className='my-1' style={{display:'flex', justifyContent:'center'}}>
  
            <button style={{border:'1px solid #fff', backgroundColor:'#030614',color:'#fff', padding:'10px 50px', borderRadius:'25px'}}> <span className='Subscribe1'
            style={{fontWeight:'500', fontSize:'22px'}}> Phase - 6 </span></button>
            
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-1</h5>
               <p>Launch Armor Ai on Mainnet. </p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal1 mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-1</h5>
               <p>Deploying Huge Marketing Campaign Investors.</p> 
             </div>   
            </div>  
             </div>
          
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-1</h5>
               <p>Airdrops Campaign for exesting Investors. </p> 
             </div>   
            </div>  
             </div>


       
          
             
            </div>


            
            <div className='row p-3 '>
           
            <div className='col-lg-4 col-md-6 col-sm-12'>
               
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-1</h5>
               <p>Launch Fee-Paying Model on Our API's and SDK's. </p> 
             </div>   
            </div>  
             </div>
           
           
            <div className='col-lg-4 col-md-6 col-sm-12'>     
             <div className='card mahakal mt-4 ' style={{backgroundColor:'#030614',width:'100%'}}>
             <div className='card-body text-white'>  
               <h5 className='QQ'>2024 Q-1</h5>
               <p>Leading CEX onbord and Listing. (Binance, Bybit , KUcoin, Gate.io, CoinBase, etc). </p> 
             </div>   
            </div>  
             </div>
           


       
          
             
            </div> 

            {/*  Phase 6 end */}


           </div>

           {/*mobile view end*/}
       </section>



       <div className='mt-5'>
        
       <Footer/>
        
        </div>
     
    </>
  )
}

export default Roadmap;